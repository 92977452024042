/** @jsx jsx */
import { Grid, Box, jsx } from "theme-ui";
import React, { useCallback, useState, useEffect } from "react";
import { PageContentNonMemoized } from "gatsby-plugin-hfn-profile/components/PageContentNonMemoized";
import { Layout, Content, Section } from "maker-ui";
import { StaticImage } from "gatsby-plugin-image";
import { eventCallbackTriggers } from "../templates/wppage";
import theme from "../mark-ui/theme";
import Seo from "../components/seo";
import options from "../mark-ui/options";

const CanadaDonationRecurringMobile = () => {
  const pageContent = (id, modalTitle) => {
    const titletext = modalTitle || "Heartfulness Canada Donation";
    return `<gatsby_donation donationId = ${id} btntext = "${titletext}" btnBg = #333333 colortext = black colorprimary = #333333 colorsecondary = #333333 colorbackground = white colormuted = #f6f6f6 colorhighlight = #efeffe titletext = "Heartfulness Canada Donation" enablesigninoption = 0/>`;
  };

  const PageContent = React.memo(PageContentNonMemoized);

  const eventCallback = useCallback(
    (event) => eventCallbackTriggers("heartfulness_canada_donation", event),
    []
  );

  const [isMobile, setIsMobile] = useState(false);

  const windowSizeTracker = () => {
    return window.innerWidth < 975 ? setIsMobile(true) : setIsMobile(false);
  };

  useEffect(() => {
    windowSizeTracker();
  }, []);

  return (
    <>
      <Layout theme={theme} options={options}>
        <Seo title="Heartfulness Donations - Canada - Mobile" />
        <Content>
          <Section>
            <Box sx={{ paddingBottom: isMobile ? "0px" : "5px" }}>
              <h2
                sx={{
                  fontWeight: 450,
                  fontSize: "25px",
                  textAlign: "center!important",
                  paddingBottom: "10px",
                  color: "rgb(51, 51, 51)",
                }}
              >
                Heartfulness Canada Donations
              </h2>
              <PageContent
                eventCallback={eventCallback}
                pageContent={pageContent(13, "Contribute Now")}
              />
            </Box>
            <Grid
              columns={1}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                padding: isMobile ? "0px 10px" : "5px 75px",
              }}
            >
              <Box sx={{ marginTop: "30px" }}>
                <h3>General donations to Heartfulness Institute, Canada</h3>
                <p>
                  <b>
                    We need your financial support to help us touch many more
                    hearts.
                  </b>
                </p>
                <p sx={{ marginTop: "15px" }}>
                  Share in the work of bringing peace, compassion, happiness and
                  harmony to people in all walks of life by making a financial
                  gift to Heartfulness Institute, Canada.
                </p>
                <p>
                  Donations of any size make it possible for us to change lives
                  by teaching and promoting Heartfulness techniques. These
                  programs are led and conducted by volunteers and are taught
                  free of charge to participants. Our teams of dedicated
                  volunteers donate their time and talents, often in their
                  professional fields, and regularly cover their own expenses.
                </p>
                <p>
                  Your donation will support our programs in schools,
                  universities and colleges, corporations, and hospitals, as
                  well as any interested groups and communities.
                </p>
                <Box>
                  <PageContent
                    eventCallback={eventCallback}
                    pageContent={pageContent(13, "Contribute Now")}
                  />
                </Box>
                <Box>
                  <h3>Sign-up for Automatic Recurring Donations:</h3>
                  <p>
                    To sign-up for automated monthly donations, please download
                    and submit the completed Auto donation form to
                    <a
                      href="mailto:ca.treasurer@srcm.org"
                      sx={{ marginLeft: "5px" }}
                    >
                      ca.treasurer@srcm.org.
                    </a>
                  </p>
                  <p>
                    <a
                      href="https://cdn-prod.heartfulness.org/donations-doc/Auto-Donation-Form.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Auto donation form PDF
                      <StaticImage
                        src="../../../apps/static/img/pdficon.png"
                        alt="Logo"
                        sx={{ marginTop: "5px" }}
                      />
                    </a>
                    <br />
                    For all inquiries or to cancel your donation, please contact
                    <a
                      href="mailto:ca.treasurer@srcm.org"
                      sx={{ marginLeft: "5px" }}
                    >
                      ca.treasurer@srcm.org
                    </a>
                  </p>
                  <h3>Donate by E-transfer:</h3>
                  <p>
                    The easiest way to donate is by sending an E-transfer to
                    <a
                      href="mailto:us.ca.treasurer@srcm.org"
                      sx={{ marginLeft: "5px" }}
                    >
                      ca.treasurer@srcm.org
                    </a>
                  </p>
                  <p>
                    Please provide your name, address, phone number and email
                    address in the message box prior to sending, along with the
                    purpose of your donation.
                  </p>
                  <h3>Donate by Cheque:</h3>
                  <p>
                    Kindly make cheques payable to Heartfulness Institute and
                    mail them to:
                  </p>
                  <p>
                    Heartfulness Institute – Treasurer’s Office
                    <br />
                    1559 Bayshire Drive
                    <br />
                    Oakville, ON L6H 6E5
                  </p>
                </Box>
                <h3>A note about donations received in Canada:</h3>
                <p>
                  All donations made are voluntary and are suggested to be made
                  to the general fund of Heartfulness Institute. General funds
                  are used for projects in areas of COVID-19 care, the
                  environment and green initiatives, positive lifestyle, and
                  sustainability initiatives, among others.
                </p>
                <p>
                  {" "}
                  If you would like to make a gift for a specific purpose,
                  please contact the Treasurer at{" "}
                  <a href="mailto:ca.treasurer@srcm.org">
                    ca.treasurer@srcm.org
                  </a>{" "}
                  beforehand with your request.
                </p>
              </Box>
            </Grid>
          </Section>
        </Content>
      </Layout>
    </>
  );
};

export default CanadaDonationRecurringMobile;
